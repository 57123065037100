import React from "react";
import AppContainer from "../AppContainer";
import { FlagsProvider } from "../orderingFlags";

const FeaturesContainer = () => {
  return (
    <FlagsProvider
      flags={{
        allowedRegister: false,
        minimumOrder: false,
        volumeControl: { enabled: false },
        allowedCountries: [
          { code: "FR", name: "France" },
          { code: "DE", name: "Germany" },
        ],
        footers: {
          imprint: {
            markdown: false,
            links: {
              en: "https://www.scribos.com/imprint-conditions-of-use",
              de: "https://www.scribos.com/de/impressum-nutzungsbedingungen",
              fr: "https://www.scribos.com/fr/mentions-legales-et-conditions-dutilisation",
              zh: "https://www.scribos.com/imprint-conditions-of-use",
            },
          },
          privacypolicy: {
            markdown: false,
            links: {
              en: "https://www.scribos.com/privacy-policy",
              de: "https://www.scribos.com/de/datenschutz",
              fr: "https://www.scribos.com/fr/declaration-de-protection-des-donnees",
              zh: "https://www.scribos.com/privacy-policy",
            },
          },
          contact: {
            markdown: false,
            links: {
              en: "https://www.scribos.com/contact",
              de: "https://www.scribos.com/de/kontakt",
              fr: "https://www.scribos.com/fr/contact",
              zh: "https://www.scribos.com/contact",
            },
          },
        },
        companyProfile: {
          userPanel: false,
          nonEditableAddressCategories: [],
        },
      }}
    >
      <AppContainer />
    </FlagsProvider>
  );
};

export default FeaturesContainer;
